<template>
    <div class="row">
        <div class="col-12">
            <div class="main-title-wrapper">
                <h1><slot name="title"></slot></h1>
                <div class="stats">
                    Taniana - <a href="https://instagram.com/tani.paris" style="color:#f43d6a">@tani.paris</a> - <slot name="date"></slot>
                </div>
            </div>
            
        </div>
    </div>
</template>