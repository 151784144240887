<template>
  <div class="blog-post">
    <Navbar/>  
    <div class="container-fluid">
      <SubNav>
        <template #current>
          выбрать программу
        </template>
      </SubNav>
      <div class="row">
        <div class="col-12 offset-lg-3 col-lg-6">
            <div class="row">
                <div class="col-12">
                    <div class="main-title-wrapper">
                        <h1>Выбрать программу</h1>
                    </div>
                    
                </div>
            </div>

          <PostBodyIntroduction>
              <template #introduction>
                  <p>
                      Французское высшее образование приняло систему LMD (licence-master-doctorat). Большинство присуждаемых степеней дают кредиты ECTS, признанные во многих странах Европейского Союза и во всем мире.
                  </p>
                  <p>
                      Как правило, высшие учебные заведения Франции используют такую классификацию программ:
                  </p>
              </template>
          </PostBodyIntroduction>

          <PostBodySection>
            <template #title>
              Bachelor (BBA, Licence..)
            </template>
            <template #content>
              <div>
                  <p>
                      программа бакалавриата, на которую можно поступить сразу после окончания средней школы в своей стране. Обучение может быть как на английском (BBA), так и на французском языке (бакалавриат по специальностям, программы Grande Ecole и так далее). Как правило, частные высшие учебные заведения Франции предлагают такие программы сроком на 3 или 4 года. 
                  </p>
                  <p>
                      Пример программ: 
                  </p>
                  <ul>
                      <li>Global BBA - Skema Business School</li>
                      <li>CESEM - Neoma Business School</li>
                      <li>Bachelor (3-year degree) - IPAG Business School</li>
                  </ul>
              </div>
            </template>
          </PostBodySection>

          <PostBodySection>
            <template #title>
              Master in Management (MIM)
            </template>
            <template #content>
              <div>
                  <p>
                      программа магистратуры на 2 года. На английском или французском языке. Как правило, первый год - это общий менеджмент, на втором году обучения можно выбрать специализацию, например, одну из программ Master of Science (MSc).
                  </p>
                  <p>
                      Пример программ: 
                  </p>
                  <ul>
                      <li>Grande Ecole Programme - Montpellier BS</li>
                      <li>Grande Ecole Programme - IPAG BS</li>
                      <li>Grande Ecole Programme/Master in Management - SKEMA BS</li>
                  </ul>
              </div>
            </template>
          </PostBodySection>

          <PostBodySection>
            <template #title>
              Master of Science (MSc)
            </template>
            <template #content>
              <div>
                  <p>
                      программа магистратуры на английском языке на 1 год. Можно выбрать одну из предложенных специализаций. Как правило, они разделены по секторам: Финансы, Маркетинг, Бизнес и Стратегия, Менеджмент и тд. Часто для подтверждения диплома требуется пройти 1 обязательную стажировку в компании после окончания учебной программы. 
                  </p>
                  <p>
                      Пример программ: 
                  </p>
                  <ul>
                      <li>MSc Global Luxury and Management - SKEMA BS</li>
                      <li>MSc Corporate Finance - Neoma BS</li>
                      <li>MSc International Business - Montpellier BS</li>
                  </ul>
              </div>
            </template>
          </PostBodySection>

          <PostBodySection>
            <template #title>
              Mastère Spécialisé
            </template>
            <template #content>
              <div>
                  <p>
                      программа магистратуры part-time на французском языке, которую можно совмещать с профессиональной деятельностью. Ритм меняется в зависимости от программы (например, 1 неделя учебы и 3 недели работы и т.д.). MS возможно проходить по контракту альтернанс (работодатель платит за обучение и начисляет зарплату), который подразделяется на 2 типа - apprentissage и contrat de professionnalisation. Для иностранных студентов есть ограничения - в первый год обучения по студенческой визе нельзя заключать этот тип контракта. Кроме того, возраст должен быть до 30 лет. Иностранцы, находящиеся по другому типу визы (например, семейной) и состоящие на бирже труда (Pole Emploi) могут проходить альтернанс без ограничений (contrat de professionnalisation).
                  </p>
                  <p>
                      Пример программ: 
                  </p>
                  <ul>
                      <li>MS supply chain management & purchasing - SKEMA BS</li>
                  </ul>
              </div>
            </template>
          </PostBodySection>

          <PostBodySection>
            <template #title>
              MBA - Master in Business Administration
            </template>
            <template #content>
              <div>
                  <p>
                      Магистерская программа для людей с опытом работы от 3-х лет.
                  </p>
                  <p>
                      Пример программ: 
                  </p>
                  <ul>
                      <li>Global MBA - ESSEC Business School</li>
                      <li>MBA - IPAG BS</li>
                  </ul>
              </div>
            </template>
          </PostBodySection>

          <PostBodySection>
            <template #title>
              Executive Master/MBA
            </template>
            <template #content>
              <div>
                  <p>
                      магистерские программы для людей с опытом работы от 8-10 лет, менеджеров. Как правило, проходят в режиме part-time и совместимы с профессиональной деятельностью. Эти программы призваны дать существенный рывок в карьере. Средний возраст учащихся - 40 лет.
                  </p>
                  <p>
                      Пример программ: 
                  </p>
                  <ul>
                      <li>Executive MBA - ESSEC BS</li>
                  </ul>
              </div>
            </template>
          </PostBodySection>

          <PostBodySection>
            <template #title>
              Doctorate/PHD
            </template>
            <template #content>
              <div>
                  <p>
                    ученая степень дает право ее обладателю преподавать в университете по специальности. Срок обучения - 3-4 года. Как правило, это part-time обучение, которое совместимо с профессиональной деятельностью. 
                  </p>
                  <p>
                      Пример программ: 
                  </p>
                  <ul>
                      <li>DBA - Doctorate of Business Administration, Neoma Business School</li>
                      <li>PhD in Business Administration, SKEMA Business School</li>
                  </ul>
              </div>
            </template>
          </PostBodySection>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/common/Navbar.vue'
import PostHeader from '@/components/blog/slots/PostHeader.vue'
import PostBodyIntroduction from '@/components/blog/slots/PostBodyIntroduction.vue'
import PostBodySection from '@/components/blog/slots/PostBodySection.vue'
import SubNav from '@/components/common/slots/SubNav.vue'
export default {
  name: 'BlogPost1',
  components: {
    Navbar,
    PostHeader,
    PostBodyIntroduction,
    PostBodySection,
    SubNav
  }
}
</script>
