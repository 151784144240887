<template>
    <div class="row section-wrapper">
        <div v-if="this.$slots.title" class="col-12">
            <div class="title">
                <slot name="title"></slot>
            </div>
        </div>
        <div v-if="this.$slots.content" class="col-12">
            <div class="content">
                <slot name="content"></slot>
            </div>
        </div>
    </div>
</template>