<template>
    <div class="row d-none d-lg-block subnavigation-wrapper">
        <div class="col-12 col-lg-6 offset-lg-3">
          <div class="d-flex flex-row">
            <div class="link-wrapper">
              <router-link to="/"><u>Home</u> ></router-link>
            </div>
            <div v-if="this.$slots.link1" class="link-wrapper">
              <slot name="link1"></slot>
            </div>
            <div>
              <slot name="current"></slot>
            </div>
          </div>
        </div>
    </div>
</template>